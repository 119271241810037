.rewards {
    padding: calc(env(safe-area-inset-top) + 16px) 0 calc(env(safe-area-inset-bottom) + 16px);
}

.rewards-title {
    padding: 0 20px;
}

.rewards-subtitle {
    padding: 0 20px;
    font-size: 0.8em;
    color: #555;
}

.reward {
    display: grid;
    grid-template-columns: auto max-content;
    align-items: center;
    padding: 16px;
}

.reward-content {
    font-size: 0.8em;
}

.reward-metadata {
    margin-top: 4px;
}

.reward-available {
    font-size: 0.8em;
}

.reward-expired {
    font-size: 0.8em;
}

.reward-check {
    font-size: 1.5em;
}

.reward-gift-card {
    display: grid;
    grid-template-columns: max-content max-content auto;
    align-items: center;
}

.reward-gift-card-code {
    font-size: 0.9em;
}

.reward-reveal, .reward-hide {
    font-size: 0.9em;
}
