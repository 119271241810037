.help {
    height: 100%;
    padding: 16px;
    margin-bottom: calc(env(safe-area-inset-bottom, 0px));
}

.help-nav {
    text-align: right;
    padding: 16px 16px 0 16px;
}

.help-close-button {
    text-decoration: none;
}