body {
    font-family: ui-rounded, -apple-system, Helvetica, Arial, sans-serif;
    background: #f9f9f9;
}

.loading-indicator {
    text-align: center;
    color: #bbb;
    padding: 16px;
}

.divider {
    height: 1px;
    background: #ddd;
}

.cta {
    display: grid;
    grid-template-columns: auto max-content;
    grid-column-gap: 12px;
    align-items: center;
    padding: 16px;
}

.cta-title {
    font-weight: bold;
    margin-bottom: 12px;
}

.cta-text {
    font-size: 14px;
}

.cta-feedback {
}

.cta-nav {
    color: #bbb;
}

.cta-check {
    color: var(--color-success);
    font-size: 16px;
    margin-right: 2px;
    vertical-align: baseline;
}

.cta-check-not-earned {
    color: var(--color-muted3);
    font-size: 16px;
    margin-right: 2px;
    vertical-align: baseline;
}


.cta-xmark {
    color: var(--color-danger);
    font-size: 16px;
    margin-right: 2px;
    vertical-align: baseline;
}

.cta-lock {
    color: var(--color-muted1);
    font-size: 16px;
    margin-right: 2px;
    vertical-align: baseline;
}

.cta-warning {
    color: var(--color-warning);
    margin-right: 2px;
    vertical-align: baseline;
}

.link {
    text-decoration: underline;
    cursor: pointer;
}

.warning {
    color: var(--color-warning);
}